.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #14305e;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#autocomplete-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50px;
  padding: 4px;
  position: relative;
}

#autocomplete-wrapper .rbt-aux {
  position: absolute;
  top: 9px;
  right: 14px;
  opacity: 0.3;
}

.autocomplete-address-row {
  display: flex;
}

.utility-select {
  margin-left: 12px;
}

.utility-select .dropdown-toggle {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  height: 54px;
}

#autocomplete-wrapper input {
  background-color: transparent;
  border: 0 none;
  padding: 0 16px;
  font-size: 16px;
}

#autocomplete-wrapper input:focus {
  box-shadow: none;
}

#autocomplete-wrapper > div {
  flex: 1;
}

#autocomplete-wrapper .dropdown-menu {
  top: 8px !important;
}

#autocomplete-wrapper .dropdown-item {
  padding: 8px 16px;
}

#autocomplete-wrapper .dropdown-item mark {
  background-color: #e9f8f5;
  border-radius: 4px;
  padding: 0 4px;
}

.plan-saving {
  display: inline-block;
  margin: 6px auto 8px;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1.5px dashed #666;
  color: #666;
}

.plan-saving a {
  text-decoration: none;
  color: inherit;
}

.plan-type-options {
  position: relative;
  bottom: -1px;
}

.plan-type-options .nav-tabs {
  border-bottom: 0 none;
}

.side-modal-body {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  max-width: 100%;
  width: 60%;
  margin: 0 auto;
  padding: 88px 44px 44px;
  z-index: 10;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #ced4da;
  background-image: none;
}

#agreement-wrapper {
  border: 1px solid #ddd;
  padding: 4px 24px;
  max-height: 700px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#agreement-wrapper table,
#agreement-wrapper td {
  position: static;
}

.form-group {
  margin: 20px 0;
}

.page-wrapper {
  position: relative;
}

.page-wrapper > div {
  position: absolute;
  width: 100%;
}

#plans-wrapper {
  display: flex;
  padding-top: 20px;
}

#plans-aside {
  max-width: 480px;
  min-width: 350px;
  width: 40%;
  padding: 24px 32px;
  flex-shrink: 0;
}

#plans-list {
  padding: 24px 32px;
  flex: 1;
  overflow: hidden;
}

.plan-compare-btn,
.plan-compare-btn:hover {
  background-color: #fff;
  border: 1px solid #ddd;
}

.plan-compare-btn.active {
  background-color: #ed7549;
  color: #fff;
  border: 1px solid #ed7549;
}

.plans-filter-options {
  display: flex;
  flex-wrap: wrap;
}

.plan-row-wrapper {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin: 20px 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  transition: border-color 0.5s;
}

.plan-row-wrapper:hover {
  border-color: #999;
}

.plan-row {
  display: flex;
  padding: 0 16px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  cursor: pointer;
  margin: 8px 0;
  flex: 0 0 50%;
  overflow: hidden;
}

.checkbox-item span {
  margin-left: 8px;
  color: #666;
  font-size: 15px;
  line-height: 1.1;
}

.checkbox-item span a {
  color: #666;
}

.checkbox-item.onlyread {
  opacity: 0.4;
  cursor: default;
}

.checkbox-item.full {
  flex: 0 0 100%;
}

.plans-filter-title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rep-logo {
  width: 80px;
  height: 42px;
}

.rep-logo i {
  display: inline-block;
  font-size: 11px;
  color: #999;
  font-style: normal;
  line-height: 1;
}

.rep-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.plan-overview {
  display: flex;
  align-items: center;
}

.plan-info-group {
  display: flex;
  align-items: center;
  flex: 1;
}

.plan-info {
  flex: 1;
  text-align: center;
}

.plan-info > div {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: -4px;
}

.plan-info label {
  font-size: 14px;
  color: #666;
  line-height: 1.2;
}

.plan-content {
  flex: 1;
  padding: 20px 0;
}

.plan-options {
  width: 200px;
  flex-shrink: 0;
  border-left: 1px solid #ddd;
  margin-right: -16px;
  margin-left: 16px;
  background: rgba(237, 117, 73, 0.1);
  padding: 20px 0;
}

.plan-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.plan-info .plan-est-rate {
  color: #ed7549;
  font-size: 22px;
}

.plan-links {
  display: flex;
}

.plan-links a {
  display: block;
  margin-right: 24px;
  color: #666;
}

.plan-detail {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
  font-size: 15px;
  background-color: #fbfbfb;
}

.plan-detail.active {
  max-height: 9999px;
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.usage-adjust-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.usage-adjust-item {
  flex: 0 0 25%;
}

.usage-adjust-item .form-label {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
}

.usage-adjust-item .form-control {
  padding: 16px 4px;
  text-align: center;
  font-size: 14px;
  border: 0 none;
  background-color: #f8f9fa;
}

.premise-detail-row {
  margin-top: 12px;
  padding: 12px 16px;
  background-color: #fafafa;
}

.premise-detail {
  display: flex;
}

.premise-detail-item {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  margin: 4px 0;
  flex: 1;
}

.checkbox-row .checkbox-item {
  align-items: flex-start;
}

.checkbox-row .checkbox-item span {
  position: relative;
  top: -2px;
  margin-left: 12px;
}

.checkbox-row .checkbox-item span.error {
  color: #dc3545;
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
  color: #666;
}

.nav-tabs .nav-link.active {
  border-top: 2px solid #1bab04;
}

#plans-aside .plans-filter-dd,
#plans-aside .plans-filter-dd button {
  width: 100%;
}

.plans-filter-dd button {
  text-align: left;
  position: relative;
  padding-right: 32px;
}

.plans-filter-dd button::after {
  position: absolute;
  top: 16px;
  right: 10px;
}

.plans-filter-dd .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.plans-filter-dd .dropdown-item {
  padding: 8px 16px;
  white-space: break-spaces;
}

.dropdown-item.active,
.dropdown-item:active {
  background: linear-gradient(135deg, #17c2c4 0%, #ed7549 100%);
}

.page-link,
.page-link:hover {
  color: #666;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #ed7549;
  border-color: #ed7549;
}

.chart-tooltip {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 16px 20px;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
}

.chart-tooltip .date {
  font-size: 11px;
  color: #666;
}

.chart-tooltip .value {
  font-size: 16px;
  margin-top: 8px;
  font-weight: bold;
  color: #333;
}

.modal-header button.close {
  background-color: transparent;
  border: 0 none;
  font-size: 26px;
  font-weight: 500;
  color: #999;
}

.access-btn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  background: linear-gradient(90deg, #ff7171, #e8955b);
  padding: 8px 16px;
  margin-top: 8px;
  white-space: nowrap;
  border: 0 none;
  border-radius: 24px;
  color: #fff;
  box-shadow: 0 3px 8px rgba(232, 149, 91, 0.3);
}

.plan-tags span {
  font-size: 11px;
  font-weight: 500;
  color: #777;
  background-color: #f9f9f9;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 6px;
  margin-right: 8px;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.plan-detail-heading {
  font-size: 17px;
  margin-bottom: 8px;
  font-weight: 700;
}

.breakdown-table-wrapper {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 24px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
}

.breakdown-table-wrapper table {
  border: 1px solid #ddd;
}

.breakdown-table-wrapper td,
.breakdown-table-wrapper th {
  text-align: center;
  padding: 12px 8px;
  font-size: 13px;
}

.breakdown-table-wrapper td.disabled,
.breakdown-table-wrapper th.disabled {
  background-color: #f9f9f9;
  color: #ccc;
}

.breakdown-table-wrapper th {
  background-color: #f9f9f9;
}

.breakdown-table-wrapper td {
  background-color: #fff;
}

#plans-filter-toggle,
#plans-filter-group .close-btn {
  display: none;
}

#plans-filter-group {
  margin-top: 32px;
}

.plans-filter {
  margin-bottom: 32px;
}

.get-usage-row {
  background-color: #f8f9fa;
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.drop-area {
  padding-top: 64px;
  padding-bottom: 64px;
  border: 2px dashed #ddd;
  border-radius: 12px;
  margin-bottom: 24px;
  cursor: pointer;
}

.drop-area.active {
  background-color: #e9f8f5;
  border-color: #ed7549;
}

.list-group-item.active {
  background-color: #e9f8f5;
  border-color: #ed7549;
  color: #ed7549;
}

.list-group-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

#term-filter-wrapper {
  margin-right: 12px;
}

#get-usage-option-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.get-usage-option {
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 12px;
  flex: 1;
  padding: 16px 20px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  transition: border 0.3s;
  position: relative;
}

.get-usage-option h5 {
  font-size: 17px;
}

.get-usage-option:hover {
  border-color: #999;
}

.get-usage-option p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.2;
}

.get-usage-option i {
  display: inline-block;
  font-style: normal;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 8px;
  padding: 1px 8px;
  font-size: 13px;
  font-weight: 600;
  background: linear-gradient(135deg, #17c2c4 0%, #ed7549 100%);
  color: #fff;
}

.get-usage-option.highlight {
  border-color: #ed7549;
  padding-top: 44px;
  padding-bottom: 44px;
  background-color: rgba(237, 117, 73, 0.1);
}

.divider {
  text-align: center;
  border-bottom: 1px dotted #ccc;
  margin: 24px auto 36px;
  max-width: 540px;
}

.divider span {
  position: relative;
  font-size: 14px;
  top: 12px;
  color: #999;
  background-color: #fff;
  padding: 0 24px;
}

.container-md {
  max-width: 640px;
  margin: 44px auto 64px;
}

/* custom model */
.modal-header {
  border-bottom: 0 none;
  text-align: center;
  display: block;
}

.modal-title {
  margin-top: 32px;
}

.modal-header button.close {
  position: absolute;
  top: 16px;
  right: 20px;
}

.modal-content {
  border-radius: 12px;
  border: 0 none;
}

.modal-body {
  padding-left: 28px;
  padding-right: 28px;
}

/* fix react datepicker arrow position */
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  left: 50% !important;
  transform: translate3d(-50%, 0px, 0px) !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.tooltip-mark {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #e9c1b2;
  color: #fff;
  cursor: default;
}

.tooltip {
  max-width: 240px;
  font-weight: 500;
}

#watch-video-poster {
  padding: 1px;
}

.plan-options button {
  font-size: 15px;
  width: 80%;
}

@media (max-width: 1170px) {
  .autocomplete-address-row {
    flex-direction: column;
  }

  .utility-select {
    margin-left: 0;
    margin-top: 12px;
    flex: 1;
  }

  .utility-select .dropdown-toggle,
  .utility-select .dropdown-menu {
    width: 100%;
  }

  .plan-overview {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .plan-options {
    width: 180px;
  }

  .plan-info-group {
    width: 100%;
  }

  .plan-info-group .plan-info {
    text-align: left;
    padding-left: 4px;
  }

  .plan-intro {
    padding-left: 84px;
    margin-top: -54px;
  }
}

@media (max-width: 750px) {
  #plans-wrapper {
    flex-direction: column;
    padding-top: 0;
  }

  #plans-aside {
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .side-modal-body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  #plans-filter-toggle {
    display: inline-block;
    flex: 1;
  }

  #plans-sort-toggle {
    flex: 1;
  }

  #plans-sort-toggle .dropdown-toggle {
    width: 100%;
  }

  #plans-filter-group {
    display: none;
    margin-top: 0;
  }

  #plans-filter-group.show {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 88px 20px 44px;
  }

  #plans-filter-group.show .close-btn {
    display: block;
  }

  #plans-list-header {
    flex-direction: column;
  }

  #term-filter-wrapper {
    margin-right: 0;
    margin-bottom: 12px;
    width: 100%;
  }

  #term-filter-wrapper .plans-filter-options,
  #term-filter-wrapper .plans-filter-dd,
  #term-filter-wrapper .plans-filter-dd button {
    width: 100%;
  }

  #term-filter-wrapper .plans-filter-dd button {
    text-align: center;
  }
}

@media (max-width: 560px) {
  #plans-list {
    padding-left: 12px;
    padding-right: 12px;
  }

  .plan-info-group {
    align-items: flex-start;
  }

  .plan-options {
    width: 130px;
  }

  .plan-options button {
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .rep-logo {
    width: 65px;
    height: 33px;
  }

  .rep-logo i {
    margin-top: 4px;
    font-size: 10px;
  }

  .plan-info > div {
    font-size: 16px;
  }

  .plan-info label {
    font-size: 13px;
    line-height: 1.1;
    margin-top: 6px;
  }

  .plan-options .plan-info label {
    padding: 0 12px;
  }

  .plan-info .plan-est-rate {
    font-size: 17px;
  }

  .plan-row-wrapper {
    margin-left: -4px;
    margin-right: -4px;
  }

  .plan-row {
    padding: 0 12px;
  }

  .plan-intro {
    padding-left: 75px;
  }

  .nav-tabs .nav-link {
    font-size: 14px;
  }

  .plan-provider-contact {
    font-size: 14px;
    margin-top: 12px;
  }

  .plan-info.plan-info-terms span {
    font-size: 12px;
  }

  .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
}
:root {
  --bs-primary: #000040 !important;
}
.text-primary,
h1,
h2,
h3,
h4 {
  color: #000040 !important;
}

.btn-primary,
.bg-primary {
  background-color: #000040 !important;
}

.bg-success {
  background-color: #4ec772 !important;
}

.bg-success-50 {
  background-color: #4ec7722e !important;
}

.text-success {
  color: #4ec772 !important;
}

.text-primary,
h1,
h2,
h3,
h4 {
  color: #000040 !important;
}

.btn-primary,
.bg-primary {
  background-color: #000040 !important;
}

.bg-primary-50 {
  background-color: #007cff2e !important;
}

.border-primary {
  border-color: #000040 !important;
}

.text-orange {
  color: #ff5400 !important;
}

.bg-orange-50 {
  background-color: #ff540080 !important;
}
.bg-orange-25 {
  background-color: #ff540042 !important;
}

.border-orange {
  border-color: #ff5400;
}

.btn-orange {
  --bs-btn-color: #fff;
  background: linear-gradient(90deg, #ff7171, #e8955b) !important;
  --bs-btn-border-color: #ff5400;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e34800;
  --bs-btn-hover-border-color: #d74100;
  --bs-btn-focus-shadow-rgb: 255, 84, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #d74100;
  --bs-btn-active-border-color: #cb3b00;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ff5400;
  --bs-btn-disabled-border-color: #ff5400;
}

.fill-primary {
  fill: #000040 !important;
}

.ddd img {
  width: 120px;
  max-height: 70px;
}

.fill-success {
  fill: #4ec772 !important;
}
.tns-liveregion.tns-visually-hidden {
  display: none;
}

.gradient-custom-btn {
  background: linear-gradient(90deg, #ff7171, #e8955b) !important;
  color: rgb(255, 255, 255);
  border: 0px none;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 32px;
  border-radius: 40px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s;
  cursor: pointer;
}

.gradient-custom-btn:hover {
  --bs-btn-bg: #ff5400 !important;
  color: rgb(255, 255, 255);
  cursor: pointer;
  pointer-events: all;
}

.dLykBH:hover {
  opacity: 1 !important;
}

#autocomplete:hover {
  cursor: pointer;
}

.gradient-custom-btn-blue {
  background: linear-gradient(90deg, #2600fc, #5b7fe8) !important;
  border: var(--bs-btn-border-color);
  color: rgb(255, 255, 255);
  border: 0px none;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 32px;
  border-radius: 40px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s;
  cursor: pointer;
}

.gradient-custom-btn-blue:hover {
  background: #000040 !important;
  border: var(--bs-btn-border-color);
  color: rgb(255, 255, 255);
  border: 0px none;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 32px;
  border-radius: 40px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s;
  cursor: pointer;
}
